@import "./mixins";

.ams-icon {
  @include icon;
}

.ams-payment-icon {
  @include payment-icon;
}

.ams-icon--success {
  @include icon-success;
}

.ams-icon--success-inverse {
  @include icon-success-inverse;
}

.ams-icon--warning {
  @include icon-warning;
}

.ams-icon--warning-inverse {
  @include icon-warning-inverse;
}

.ams-icon--error {
  @include icon-error;
}

.ams-icon--error-inverse {
  @include icon-error-inverse;
}

.ams-icon--hide-password {
  @include icon-hide-password;
}

.ams-icon--show-password {
  @include icon-show-password;
}

.ams-icon--overview {
  @include icon-overview;
}

.ams-icon--profile {
  @include icon-profile;
}

.ams-icon--payment {
  @include icon-payment;
}

.ams-icon--manage {
  @include icon-manage;
}

.ams-icon--manage-users {
  @include icon-manage-users;
}

.ams-icon--vyprvpn {
  @include icon-vyprvpn;
}

.ams-icon--giganews {
  @include icon-giganews;
}

.ams-icon--texasnet {
  @include icon-texasnet;
}

.ams-icon--outfox {
  @include icon-outfox;
}

.ams-icon--search {
  @include icon-search;
}

.ams-icon--left {
  @include icon-left;
}

.ams-icon--right {
  @include icon-right;
}

.ams-icon--up {
  @include icon-up;
}

.ams-icon--down {
  @include icon-down;
}

.ams-icon--sku-mapping {
  @include icon-sku-mapping;
}

.ams-icon--active {
  @include icon-active;
}

.ams-icon--apple {
  @include icon-apple;
}

.ams-icon--google {
  @include icon-google;
}

.ams-icon--recurly {
  @include icon-recurly;
}

.ams-icon--edit {
  @include icon-edit;
}

.ams-icon--add {
  @include icon-add;
}

.ams-icon--remove {
  @include icon-remove;
}

.ams-icon--subscriptions {
  @include icon-subscriptions;
}

.ams-icon--logo-vyprvpn {
  @include icon-logo-vyprvpn;
}

.ams-icon--logo-texasnet {
  @include icon-logo-texasnet;
}

.ams-icon--logo-outfox {
  @include icon-logo-outfox;
}

.ams-icon--default {
  @include icon-default;
}

.ams-icon--default-blue {
  @include icon-default-blue;
}

.ams-icon--checkmark {
  @include icon-checkmark;
}

.ams-icon--help {
  @include icon-help;
}

.ams-icon--tenant-GF {
  @include icon-goldenfrog;
}

.ams-icon--tenant-GN {
  @include icon-giganews;
}

.ams-icon--tenant-TN {
  @include icon-texasnet;
}

.ams-icon--admin-inverse {
  @include icon-admin-inverse;
}

.ams-icon--admin {
  @include icon-admin;
}

.ams-icon--responsive-menu {
  @include icon-responsive-menu;
}

.ams-icon--responsive-menu-close {
  @include icon-responsive-menu-close;
}

.ams-icon--remove-blue {
  @include icon-remove-blue;
}

.ams-icon--manage-services {
  @include icon-manage-services;
}

.ams-icon--android {
  @include icon-android;
}

.ams-icon--anonabox {
  @include icon-anonabox;
}

.ams-icon--blackphone {
  @include icon-blackphone;
}

.ams-icon--ios {
  @include icon-ios;
}

.ams-icon--mac {
  @include icon-mac;
}

.ams-icon--qnap {
  @include icon-qnap;
}

.ams-icon--router {
  @include icon-router;
}

.ams-icon--tv {
  @include icon-tv;
}

.ams-icon--windows {
  @include icon-windows;
}

.ams-icon--mastercard {
  @include icon-mastercard;
}

.ams-icon--visa {
  @include icon-visa;
}

.ams-icon--americanexpress {
  @include icon-americanexpress;
}

.ams-icon--dinersclub {
  @include icon-dinersclub;
}

.ams-icon--jcb {
  @include icon-jcb;
}

.ams-icon--discover {
  @include icon-discover;
}

.ams-icon--paypal {
  @include icon-paypal;
}

.ams-icon--sepa {
  @include icon-sepa;
}

.ams-icon--amazon {
  @include icon-amazon;
}

.ams-icon--unionpay {
  @include icon-unionpay;
}

.ams-icon--ach {
  display: none;
}
