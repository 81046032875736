@import "./functions";

@mixin color-to-css-vars($name, $hex) {
  --color-#{$name}: #{$hex};
  --color-#{$name}-rgb: #{hex-to-rgb($hex)};
}

@mixin colors-to-css-vars($colors) {
  @each $name, $color in $colors {
    @include color-to-css-vars($name, $color);
  }
}
