@import "../colors";

$icons-path: "../../images/icons";

$background-color: #6a7486;
$size: 24px;

@mixin icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: $size;
  min-height: $size;
  min-width: $size;
  width: $size;
}

@mixin payment-icon {
  height: 1.5rem;
  margin-right: 0.75rem;
  min-height: 1.5rem;
  min-width: 2.4rem;
  width: 2.4rem;
}

@mixin icon-success {
  background-image: url("#{$icons-path}/icon-small-success.svg");
}

@mixin icon-success-inverse {
  background-image: url("#{$icons-path}/icon-small-success-inverse.svg");
}

@mixin icon-warning {
  background-image: url("#{$icons-path}/icon-small-warning.svg");
}

@mixin icon-warning-inverse {
  background-image: url("#{$icons-path}/icon-small-warning-inverse.svg");
}

@mixin icon-error {
  background-image: url("#{$icons-path}/icon-small-error.svg");
}

@mixin icon-error-inverse {
  background-image: url("#{$icons-path}/icon-small-error-inverse.svg");
}

@mixin icon-hide-password {
  background-image: url("#{$icons-path}/icon-small-hide-password.svg");
}

@mixin icon-show-password {
  background-image: url("#{$icons-path}/icon-small-show-password.svg");
}

@mixin icon-vyprvpn {
  background-image: url("#{$icons-path}/icon-vyprvpn.svg");
}

@mixin icon-giganews {
  background-image: url("#{$icons-path}/icon-giganews.svg");
}

@mixin icon-goldenfrog {
  background-image: url("#{$icons-path}/icon-goldenfrog.png");
}

@mixin icon-outfox {
  background-image: url("#{$icons-path}/icon-outfox.svg");
}

@mixin icon-overview {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-small-overview.svg");
}

@mixin icon-profile {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-small-profile.svg");
}

@mixin icon-payment {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-small-payment.svg");
}

@mixin icon-manage {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-small-manage.svg");
}

@mixin icon-manage-users {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-manage-users.svg");
}

@mixin icon-search {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-search.svg");
}

@mixin icon-left {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-left.svg");
  mask-position: center;
}

@mixin icon-right {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-right.svg");
  mask-position: center;
}

@mixin icon-up {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-left.svg");
  transform: rotate(90deg);
}

@mixin icon-down {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-left.svg");
  transform: rotate(270deg);
}

@mixin icon-sku-mapping {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-sku-mapping.svg");
}

@mixin icon-active {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-active.svg");
}

@mixin icon-apple {
  background-image: url("#{$icons-path}/icon-apple.svg");
}

@mixin icon-google {
  background-image: url("#{$icons-path}/icon-google.svg");
}

@mixin icon-recurly {
  background-image: url("#{$icons-path}/icon-recurly.svg");
}

@mixin icon-edit {
  background-image: url("#{$icons-path}/icon-edit.svg");
}

@mixin icon-add {
  background-image: url("#{$icons-path}/icon-add.svg");
}

@mixin icon-remove {
  background-image: url("#{$icons-path}/icon-remove.svg");
}

@mixin icon-subscriptions {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-subscriptions.svg");
}

@mixin icon-logo-vyprvpn {
  background-image: url("#{$icons-path}/icon-logo-vyprvpn.svg");
}

@mixin icon-logo-texasnet {
  background-image: url("#{$icons-path}/icon-logo-texasnet.svg");
}

@mixin icon-logo-outfox {
  background-image: url("#{$icons-path}/icon-logo-outfox.svg");
}

@mixin icon-default {
  background-image: url("#{$icons-path}/icon-default.svg");
}

@mixin icon-default-blue {
  background-image: url("#{$icons-path}/icon-default-blue.svg");
}

@mixin icon-checkmark {
  background-image: url("#{$icons-path}/icon-checkmark.svg");
}

@mixin icon-help {
  background-image: url("#{$icons-path}/icon-help.svg");
}

@mixin icon-admin {
  mask-image: url("#{$icons-path}/icon-admin-inverse.svg");
}

@mixin icon-admin-inverse {
  background-image: url("#{$icons-path}/icon-admin-inverse.svg");
}

@mixin icon-responsive-menu {
  background-image: url("#{$icons-path}/icon-responsive-menu.svg");
}

@mixin icon-responsive-menu-close {
  background-image: url("#{$icons-path}/icon-responsive-menu-close.svg");
}

@mixin icon-remove-blue {
  background-color: $b-300;
  mask-image: url("#{$icons-path}/icon-remove.svg");
}

@mixin icon-texasnet {
  background-image: url("#{$icons-path}/icon-texas-net.svg");
}

@mixin icon-manage-services {
  background-color: $background-color;
  mask-image: url("#{$icons-path}/icon-manage-services.svg");
}

@mixin icon-android {
  background-image: url("#{$icons-path}/icon-android.svg");
}

@mixin icon-anonabox {
  background-image: url("#{$icons-path}/icon-anonabox.svg");
}

@mixin icon-blackphone {
  background-image: url("#{$icons-path}/icon-blackphone.svg");
}

@mixin icon-ios {
  background-image: url("#{$icons-path}/icon-ios.svg");
}

@mixin icon-mac {
  background-image: url("#{$icons-path}/icon-mac.svg");
}

@mixin icon-qnap {
  background-image: url("#{$icons-path}/icon-qnap.svg");
}

@mixin icon-router {
  background-image: url("#{$icons-path}/icon-router.svg");
}

@mixin icon-tv {
  background-image: url("#{$icons-path}/icon-tv.svg");
}

@mixin icon-windows {
  background-image: url("#{$icons-path}/icon-windows.svg");
}

@mixin icon-mastercard {
  background-image: url("#{$icons-path}/payments/icon-cc-mastercard.svg");
}

@mixin icon-visa {
  background-image: url("#{$icons-path}/payments/icon-cc-visa.svg");
}

@mixin icon-americanexpress {
  background-image: url("#{$icons-path}/payments/icon-cc-amex.svg");
}

@mixin icon-dinersclub {
  background-image: url("#{$icons-path}/payments/icon-cc-diners-club.svg");
}

@mixin icon-jcb {
  background-image: url("#{$icons-path}/payments/icon-cc-jcb.svg");
}

@mixin icon-discover {
  background-image: url("#{$icons-path}/payments/icon-cc-discover.svg");
}

@mixin icon-paypal {
  background-image: url("#{$icons-path}/payments/icon-paypal.svg");
}

@mixin icon-sepa {
  background-image: url("#{$icons-path}/payments/icon_sepa.svg");
}

@mixin icon-amazon {
  background-image: url("#{$icons-path}/payments/icon_amazon_pay.svg");
}

@mixin icon-unionpay {
  background-image: url("#{$icons-path}/payments/icon-cc-unionpay.svg");
}
