@mixin fonts {
  --font-family: "Open Sans", sans-serif;
  --font-size-root: 12px;
  --h1-font-size: 4rem;
  --h1-font-size-mobile: 2.5rem;
  --h2-font-size: 2rem;
  --h2-font-size-mobile: 1.75rem;
  --h3-font-size: 1.5rem;
  --font-size-medium: 1.25rem;
  --font-size-small: 1rem;
  --h1-font-weight: 800;
  --h2-font-weight: 800;
  --h3-font-weight: bold;
  --font--weight-medium: 600;
  --h-font-family: "Open Sans", sans-serif;
  --h1-text-transform: normal;
  --h2-text-transform: normal;
}
