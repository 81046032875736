@import "./colors";

@mixin content-loader($elemBgColor) {
  border-radius: 8px;
  height: 20.8rem;
  margin: 0 0 5rem;
  width: 100%;
  z-index: 44;

  &-inside {
    height: 100%;
    padding: 2.5rem 0 5rem 2.5rem;
    width: 100%;
  }

  &-title {
    background-color: $elemBgColor;
    border-radius: 17px;
    height: 1.5rem;
    margin: 0 13rem 3.7rem 0;
    position: relative;
    width: 14.583rem;
    z-index: 46;
  }

  &-content {
    width: 100%;
  }

  &-left {
    background-color: $elemBgColor;
    border-radius: 17px;
    display: inline-block;
    height: 1rem;
    margin: 0 11.6rem 2.5rem 0;
    position: relative;
    width: 5.8rem;
    z-index: 46;
  }

  &-right {
    background-color: $elemBgColor;
    border-radius: 17px;
    display: inline-block;
    height: 1rem;
    margin: 0 0 2.5rem;
    position: relative;
    width: 10rem;
    z-index: 46;
  }
}
