@import "./variables";

@mixin hidden-mobile {
  @media (max-width: $mobile-breakpoint-up) {
    display: none;
  }
}

@mixin hidden-desktop {
  @media (min-width: $mobile-breakpoint-down) {
    display: none;
  }
}
