@import "variables";

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-200.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-300.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  src: local("Source Sans Pro ExtraLight Italic"),
    local("SourceSansPro-ExtraLightItalic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-200italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-regular.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: local("Source Sans Pro Light Italic"), local("SourceSansPro-LightItalic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-300italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-600.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold Italic"),
    local("SourceSansPro-SemiBoldItalic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-600italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-700.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldItalic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-700italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: local("Source Sans Pro Black Italic"), local("SourceSansPro-BlackItalic"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-900italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
    url("#{$fonts-path}/source-sans-pro/source-sans-pro-900.woff2")
      format("woff2");
}
