@font-face {
  font-family: "Averia Serif Libre";
  font-style: normal;
  font-weight: 300;
  src: local("Averia Serif Libre Light"), local("AveriaSerifLibre-Light"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-300.woff2")
      format("woff2");
}

@font-face {
  font-family: "Averia Serif Libre";
  font-style: italic;
  font-weight: 300;
  src: local("Averia Serif Libre Light Italic"),
    local("AveriaSerifLibre-LightItalic"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-300italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Averia Serif Libre";
  font-style: italic;
  font-weight: 400;
  src: local("Averia Serif Libre Italic"), local("AveriaSerifLibre-Italic"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-italic.woff2")
      format("woff2");
}

@font-face {
  font-family: "Averia Serif Libre";
  font-style: normal;
  font-weight: 400;
  src: local("Averia Serif Libre Regular"), local("AveriaSerifLibre-Regular"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-regular.woff2")
      format("woff2");
}

@font-face {
  font-family: "Averia Serif Libre";
  font-style: normal;
  font-weight: 700;
  src: local("Averia Serif Libre Bold"), local("AveriaSerifLibre-Bold"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-700.woff2")
      format("woff2");
}

@font-face {
  font-family: "Averia Serif Libre";
  font-style: italic;
  font-weight: 700;
  src: local("Averia Serif Libre Bold Italic"),
    local("AveriaSerifLibre-BoldItalic"),
    url("#{$fonts-path}/averia-serif-libre/averia-serif-libre-v9-latin-700italic.woff2")
      format("woff2");
}
