@import "./fonts/index";
@import "colors";
@import "./responsive/variables";

h1,
h2,
h3 {
  color: $n-900;
  font-family: var(--h-font-family);
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  letter-spacing: -1px;
  line-height: 1.25;
  margin-top: 0;
  text-transform: var(--h1-text-transform);

  @media (max-width: $mobile-breakpoint-up) {
    font-size: var(--h1-font-size-mobile);
  }
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  letter-spacing: -0.5px;
  line-height: 1.5;
  text-transform: var(--h2-text-transform);

  @media (max-width: $mobile-breakpoint-up) {
    font-size: var(--h2-font-size-mobile);
  }
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
}
