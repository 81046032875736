@import-normalize;

@import "~react-toastify/dist/ReactToastify.css";
@import "./assets/styles/main";

* {
  box-sizing: border-box;
}

html,
body,
.root {
  height: 100vh;
}

html {
  color: $n-900;
  font-family: var(--font-family);
  font-size: var(--font-size-root);
}

body {
  margin: 0;
}

input,
select,
textarea,
button {
  font: inherit;
}
