@import "../colors";

@mixin input-outline {
  appearance: none;
  box-shadow: 0 0 0 3px transparent;
  outline: 0;

  &:active,
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px $b-100;
  }
}

@mixin input-error {
  &,
  &:active,
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px $r-400 !important;
  }
}

@mixin input-success {
  &,
  &:active,
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px $g-300;
  }
}
