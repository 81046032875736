@import "../../assets/styles/variables";
@import "../../assets/styles/colors";
@import "../../assets/styles/mixins/global";
@import "../../assets/styles/themes/mixins";
@import "./colors";
@import "../../assets/styles/spacing/variables";
@import "../../assets/styles/responsive/variables";

@include global {
  --selected-icon-color: var(--color-primary);
}

@include global($brand: $brand-texas) {
  --selected-icon-color: var(--color-icon-background-active);
  @include colors-to-css-vars($colors-texas-net);
}

.navigation {
  flex: 1;
  padding-top: $spacing-2xl;

  @media (max-width: $mobile-breakpoint-up) {
    padding-bottom: $spacing-4xl;
    padding-top: 0;
  }
}

.navigation__item {
  align-items: center;
  border: solid 3px transparent;
  color: $n-050;
  display: flex;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-weight: 600;
  line-height: 1.4;
  padding: 21px $spacing-l;
  text-decoration: none;

  @media (max-width: $mobile-breakpoint-up) {
    padding: $spacing-l;
  }

  .navigation_point {
    margin-right: $spacing-m;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $n-900;
    color: $n-000;
  }

  &:active {
    color: $n-400;
  }

  &:focus {
    border: solid 3px $b-100;
    box-shadow: 0 0 2px 0 $b-100;
    outline: none;
  }
}

.navigation__icon {
  margin-right: $spacing-m;
}

.navigation__divider {
  background-color: $n-900;
  border-width: 0;
  height: 1px;
}

.navigation__item--selected {
  background-color: $n-900;
  color: $n-000;

  @media (max-width: $mobile-breakpoint-up) {
    background-color: $n-900;
  }

  .navigation__icon {
    background-color: var(--selected-icon-color);
  }
}
