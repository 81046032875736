@import "../responsive/variables";
@import "../spacing/variables";

.Toastify__toast {
  border-radius: 3px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
  min-height: 5rem;
}

.Toastify__toast-body {
  align-items: center;
  color: $n-000;
  display: flex;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  font-weight: var(--font--weight-medium);
  justify-content: center;

  @media (max-width: $mobile-breakpoint-up) {
    justify-content: flex-start;
  }
}

.Toastify__toast--success {
  background-color: $g-300;
}

.Toastify__toast--warning {
  background-color: $y-300;
}

.Toastify__toast--error {
  background-color: $gn-primary;
}

.Toastify__toast-container--bottom-center {
  width: 70%;

  @media (max-width: $mobile-breakpoint-up) {
    transform: none;
    width: auto;
  }
}

.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-right,
.Toastify__toast-container--bottom-center {
  bottom: 30px;

  @media (max-width: $mobile-breakpoint-up) {
    bottom: $spacing-s;
    left: $spacing-s;
    right: $spacing-s;
  }
}
