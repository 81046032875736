@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: local("Lato Hairline"), local("Lato-Hairline"),
    url("#{$fonts-path}/lato/lato-v16-latin-100.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"),
    url("#{$fonts-path}/lato/lato-v16-latin-100italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url("#{$fonts-path}/lato/lato-v16-latin-300.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    url("#{$fonts-path}/lato/lato-v16-latin-300italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url("#{$fonts-path}/lato/lato-v16-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"),
    url("#{$fonts-path}/lato/lato-v16-latin-italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url("#{$fonts-path}/lato/lato-v16-latin-700.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url("#{$fonts-path}/lato/lato-v16-latin-700italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"),
    url("#{$fonts-path}/lato/lato-v16-latin-900.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src: local("Lato Black Italic"), local("Lato-BlackItalic"),
    url("#{$fonts-path}/lato/lato-v16-latin-900italic.woff2") format("woff2");
}
