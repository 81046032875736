$spacing-0: 0;
$spacing-2xs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-m: 1.5rem;
$spacing-l: 2rem;
$spacing-xl: 2.5rem;
$spacing-2xl: 3rem;
$spacing-3xl: 4rem;
$spacing-4xl: 5rem;
$spacing-5xl: 6rem;
