@import "variables";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("#{$fonts-path}/open-sans/open-sans-300.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
    url("#{$fonts-path}/open-sans/open-sans-300italic.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("#{$fonts-path}/open-sans/open-sans-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("#{$fonts-path}/open-sans/open-sans-600.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
    url("#{$fonts-path}/open-sans/open-sans-italic.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url("#{$fonts-path}/open-sans/open-sans-600italic.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("#{$fonts-path}/open-sans/open-sans-700.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
    url("#{$fonts-path}/open-sans/open-sans-700italic.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("#{$fonts-path}/open-sans/open-sans-800.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
    url("#{$fonts-path}/open-sans/open-sans-800italic.woff2") format("woff2");
}
