@import "./variables";

$spacings: "0" $spacing-0, "2xs" $spacing-2xs, "xs" $spacing-xs, "s" $spacing-s,
  "m" $spacing-m, "l" $spacing-l, "xl" $spacing-xl, "2xl" $spacing-2xl,
  "3xl" $spacing-3xl, "4xl" $spacing-4xl, "5xl" $spacing-5xl;

@mixin spacing($key, $value, $property, $propertyKey) {
  .#{$propertyKey}a-#{$key} {
    #{$property}: $value !important;
  }

  .#{$propertyKey}l-#{$key} {
    #{$property}-left: $value !important;
  }

  .#{$propertyKey}r-#{$key} {
    #{$property}-right: $value !important;
  }

  .#{$propertyKey}t-#{$key} {
    #{$property}-top: $value !important;
  }

  .#{$propertyKey}b-#{$key} {
    #{$property}-bottom: $value !important;
  }
}

@mixin generate-spacing() {
  @each $key, $value in $spacings {
    @include spacing($key, $value, "margin", "m");
    @include spacing($key, $value, "padding", "p");
  }
}
