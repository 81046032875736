@import "../themes/mixins";

@mixin overflow {
  overflow: auto;
}

@mixin flex {
  display: flex;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin flex-column {
  flex-direction: column;
}

@mixin flex-align-center {
  align-items: center;
}

@mixin flex-justify-center {
  justify-content: center;
}
