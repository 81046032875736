@import "../../assets/styles/colors";
@import "../../assets/styles/responsive/variables";

.ams-payment-form__element {
  .recurly-element {
    background-color: $n-000;
    border-radius: 3px;
    color: $n-900;
    font-size: var(--font-size-medium);
    font-weight: normal;
    height: 42px;
    line-height: 1.5;
    margin-top: 0;
    padding: 9px 12px;
    width: 100%;
  }

  .recurly-element:not(.recurly-element-invalid) {
    border: solid 1px $n-050;
  }

  .recurly-element-focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px $b-100;
  }

  .recurly-element-number {
    width: 100%;
  }
}

.ams-payment-form__element--short {
  width: 7.5rem;
}

.ams-payment-form__expiration-date {
  display: flex;

  .ams-payment-form__element {
    margin-right: 1rem;
  }
}

.ams-payment-form__elements--hidden {
  display: none;
}

.recurly-element-year,
.recurly-element-month {
  background-image: url("../../assets/images/select-down.svg");
  background-position: right 3px center;
  background-repeat: no-repeat;
}

.ams-form-group .ams-label__height-fixed {
  align-items: center;
  display: flex;
  height: 30px;
  margin-bottom: 0;
}

.ams-payment-form__row {
  align-items: flex-end;
  display: flex;
  flex-direction: row;

  @media (max-width: $mobile-breakpoint-up) {
    flex-wrap: wrap;
  }
}
