@import "../variables";

@mixin global($brand: null) {
  $root-selector: ":root";

  @if $brand != null {
    $root-selector: '#{$root-selector}[data-brand="#{$brand}"]';
  }

  #{$root-selector} {
    @content;
  }
}
