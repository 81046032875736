$n-000: #fff;
$n-010: #fafbfc;
$n-020: #f4f5f7;
$n-030: #ebecef;
$n-040: #dfe1e6;
$n-050: #c4c9d0;
$n-060: #bcc0c8;
$n-070: #adb2bb;
$n-080: #a2a8b2;
$n-090: #969ba5;
$n-100: #888f9b;
$n-200: #747d8d;
$n-300: #6a7486;
$n-400: #5e6879;
$n-500: #565f6f;
$n-600: #515969;
$n-700: #474f5c;
$n-800: #3c424d;
$n-900: #2d323b;
$r-050: #ffebe5;
$r-075: #ffbdad;
$r-100: #ff8f73;
$r-200: #ff7452;
$r-300: #ff5630;
$r-350: #ff2768;
$r-400: #de350b;
$r-425: #e71717;
$r-450: #d2332a;
$r-500: #bf2600;
$r-600: #a33131;
$r-700: #771e1e;
$b-050: #deebff;
$b-075: #b3d4ff;
$b-087: #6981c1;
$b-100: #4c9aff;
$b-200: #2684ff;
$b-250: #0e8efb;
$b-300: #0065ff;
$b-350: #035ad6;
$b-400: #0052cc;
$b-500: #0747a6;
$b-550: #0045a6;
$b-555: #144593;
$b-575: #2727e1;
$b-585: #1a56b6;
$b-586: #00378f;
$b-595: #0e3065;
$b-600: #15084f;
$y-050: #fffae6;
$y-075: #fff0b3;
$y-100: #ffe380;
$y-200: #ffc400;
$y-300: #ffab00;
$y-400: #ff991f;
$y-500: #ff8b00;
$g-050: #e2ffee;
$g-075: #abf5d1;
$g-100: #79f2c0;
$g-200: #57d9a3;
$g-300: #36b37e;
$g-350: #00b528;
$g-360: #00a124;
$g-370: #149027;
$g-400: #00875a;
$g-450: #017a1c;
$g-500: #064;
$gb-100: #4dace1;
$gb-200: #2684b9;
$p-050: #eae6ff;
$p-075: #c0b6f2;
$p-100: #998dd9;
$p-200: #8777d9;
$p-250: #5d5acf;
$p-300: #6554c0;
$p-400: #5243aa;
$p-500: #403294;
$gray-900: #212529;
$gn-primary: #c83933;
$gn-secondary: #2e304e;
$vypr-primary: #0e8efb;
$phmgmt-primary: #00b528;
$gray-light: #d8d8d8;
