@import "../../assets/styles/colors";
@import "../../assets/styles/spacing/variables";

.navbar {
  align-items: center;
  display: flex;
  flex-direction: row;

  &__link,
  &__button {
    color: $n-000;
    display: inline-flex;
    font-size: var(--font-size-root);
    font-weight: bold;
    padding: 0 $spacing-s;
  }

  &__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
  }

  &__link {
    text-decoration: none;
  }
}
